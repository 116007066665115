<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="ESG_MANAGEMENT_ITEM_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="esgManagementItemTypeCd"
            label="분류"
            v-model="searchParam.esgManagementItemTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="항목 목록"
      rowKey="esgManagementItemId"
      :columns="grid.columns"
      :data="grid.data"
      :editable="editable"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="추가" :showLoading="false" icon="add" @btnClicked="add" />
          <c-btn
            v-if="editable && grid.data.length > 0"
            :url="insertUrl"
            :isSubmit="isSave"
            :param="grid.data"
            mappingType="POST"
            label="저장"
            icon="save"
            @beforeAction="saveItem"
            @btnCallback="saveCallback" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
          <!-- <c-btn v-if="editable && grid.data.length > 0" label="LBLREMOVE" :showLoading="false" :editable="editable" icon="remove" @btnClicked="remove" /> -->
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'esgManagementItem',
  data() {
    return {
      searchParam: {
        plantCd: '',
        trainingTypeCd: null,
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'esgManagementItemTypeCd',
            field: 'esgManagementItemTypeCd',
            label: '분류',
            align: 'center',
            sortable: true,
            style: 'width:130px',
            type: 'select',
            codeGroupCd: 'ESG_MANAGEMENT_ITEM_TYPE_CD',
          },
          {
            required: true,
            name: 'esgManagementItemName',
            field: 'esgManagementItemName',
            label: '항목명',
            align: 'left',
            sortable: true,
            style: 'width:350px',
            type: 'text',
          },
          {
            name: 'gmsIndexCd',
            field: 'gmsIndexCd',
            label: 'GMS Index',
            align: 'center',
            sortable: true,
            style: 'width:130px',
            type: 'select',
            codeGroupCd: 'GMS_INDEX_CD',
          },
          {
            name: 'sasbIndexCd',
            field: 'sasbIndexCd',
            label: 'SASB Index',
            align: 'center',
            sortable: true,
            style: 'width:130px',
            type: 'select',
            codeGroupCd: 'SASB_INDEX_CD',
          },
          {
            name: 'esgManagementItemUnitCd',
            field: 'esgManagementItemUnitCd',
            label: '단위',
            align: 'center',
            sortable: true,
            style: 'width:130px',
            type: 'select',
            codeGroupCd: 'ESG_MANAGEMENT_ITEM_UNIT_CD',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '정렬순서',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'number',
          },
        ],
        data: [],
      },
      editable: true,
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      isSave: false,
      listUrl: '',
      insertUrl: '',
      searchUrl: '',
      deleteUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.esg.item.list.url;
      this.insertUrl = transactionConfig.sai.esg.item.insert.url;
      this.deleteUrl = transactionConfig.sai.esg.item.delete.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    add() {
      this.grid.data.splice(0, 0, {
        plantCd: '',
        esgManagementPointCd: null,
        esgManagementItemTypeCd: null,
        esgManagementItemId: uid(),
        esgManagementItemName: '',
        gmsIndexCd: null,
        sasbIndexCd: null,
        esgManagementItemUnitCd: null,
        sortOrder: '',
        regUserId: this.$store.getters.user.userId,
        chgUserId: this.$store.getters.user.userId,
        useFlag: 'Y',
        editFlag: 'C',
      })
    },
    saveItem() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: '확인',
          message: '저장하시겠습니까?',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    // remove() {
    //   let selectData = this.$refs['table'].selected;
    //   if (!selectData || selectData.length === 0) {
    //     window.getApp.$emit('ALERT', {
    //       title: '안내',
    //       message: '선택된 항목이 없습니다.',
    //       type: 'warning', // success / info / warning / error
    //     });
    //   } else {
    //     window.getApp.$emit('CONFIRM', {
    //     title: '확인',
    //     message: '삭제하시겠습니까?', 
    //     // TODO : 필요시 추가하세요.
    //     type: 'warning', // success / info / warning / error
    //     // 확인 callback 함수
    //     confirmCallback: () => {
    //       this.$http.url = this.deleteUrl;
    //       this.$http.type = 'DELETE';
    //       this.$http.param = {
    //         data: Object.values(selectData)
    //       }
    //       this.$http.request(() => {
    //         this.$_.forEach(selectData, item => {
    //           this.grid.data = this.$_.reject(this.grid.data, { esgManagementItemId: item.esgManagementItemId })
    //         })
    //         window.getApp.$emit('APP_REQUEST_SUCCESS');
    //       },);
    //     },
    //     // 취소 callback 함수
    //     cancelCallback: () => {
    //     },
    //   });
    //   }
    // },
  }
};
</script>
